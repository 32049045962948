import { Action } from "redux";
import { post } from ".";
import * as AuthActions from "../actions/auth";
import { Api } from "../enums/api";
import {
  changeTranslation,
  errorResponseNotification,
  getCookie,
} from "../utils";
import axios from "axios";
import * as LangActions from "actions/lang";
import { RootState } from "reducers";
import { setSessionLanguage } from "./lang";
import i18n from "i18n";

let timer = 0;
export const getSessionStatus = (isNeedToLoading?: boolean) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    // eslint-disable-next-line no-lone-blocks

    !isNeedToLoading && dispatch(AuthActions.setAuthLoading());

    axios
      .post(Api.GetSessionStatus)
      .then((res) => {
        if (res?.data === null) {
          dispatch(AuthActions.setAuthFail());
          errorResponseNotification(res.data.status, 5000, res?.data?.error);
          dispatch(statusControl(res.status) as any);
          dispatch(AuthActions.setSessionStatus("unknown"));
        } else {
          if (res?.data?.response?.language) {
            changeTranslation(res.data.response.language);
            dispatch(LangActions.setLanguage(res.data.response.language));
          } else if (res?.data?.response?.language === null) {
            const lang = i18n.language.split("-")[0];
            dispatch(setSessionLanguage(lang) as any);
          }

          dispatch(AuthActions.setAuthParams(res.data.response));
        }
      })
      .catch((error) => {
        errorResponseNotification(
          error?.response?.status,
          5000,
          error.data?.error
        );
        dispatch(statusControl(error?.response?.status) as any);
        dispatch(AuthActions.setSessionStatus("unknown"));
        dispatch(AuthActions.setAuthFail());

        console.error(error);
      });
  };
};

export enum LogOutDispatch {
  CONFIRM_BY_EMAIL = "confirm_joining",
  CONFIRM_BY_LINK = "confirm_join_by_link",
}

export const logOut = (
  withoutSessionStatus?: boolean,
  newDispatch?: LogOutDispatch,
  withoutReload?: boolean
) => {
  return (dispatch: (action: Action) => void, getState: () => RootState) => {
    dispatch(AuthActions.setAuthLoading());

    post(Api.LogOut)
      .then(() => {
        sessionStorage.removeItem("redirectAfterLogin");
        dispatch(AuthActions.setAuthLoadingEnd());
        dispatch(AuthActions.setInitialApp());
        if (!withoutSessionStatus) {
          dispatch(getSessionStatus() as any);
        }
        if (!withoutReload) window.location.reload();
      })
      .catch((error) => {
        dispatch(AuthActions.setInitialApp());
        console.error(error);
      });
  };
};

export const statusControl = (statusCode: number) => {
  return (dispatch: (action: Action) => void) => {
    if ((statusCode === 401 && !getCookie("sid")) || statusCode === 400) {
      setTimeout(
        () => dispatch(getSessionStatus(false) as any),
        timer++ * 1000
      );
    }
  };
};
