export default interface AuthState {
  isLoading: boolean;
  isError: boolean;
  isAuth: boolean;
  sid: string;
  status_session: StatusSession;
  email?: string;
  first_name?: string;
  max_age?: number;
  plan_name?: string;
  subscription_cost?: number;
  subscription_periodicity?: any;
  plan_id?: number;
  isNeedToHideHeader: boolean;
  avatar_url?: string;
  company_role?: "owner" | "user" | "admin";
  consent_on_cookie: boolean;
}

export enum StatusSession {
  INITIAL = "initial",
  UNKNOWN = "unknown",
  UNCONFIRMED_EMAIL = "unconfirmed_email",
  PLAN_SELECTED = "plan_selected",
  PLAN_SELECTION = "plan_selection",
  PAID = "paid",
  CONFIRMED_EMAIL = "confirmed_email",
  QUESTIONNAIRE = "questionnaire",
  CLOSED = "closed",
  SET_NAME_AND_EMAIL = "set_name_and_email",
  OPEN = "open",
}
