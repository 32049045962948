import ReactGA from "react-ga";

export const sendEvent = (
  category: string,
  label: string,
  action: string,
  value: number
) => {
  ReactGA.event({ category, action, label, value });
};
