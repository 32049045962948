import { StatusSession } from "interfaces/state/auth";
import * as Const from "../constants";

export const setAuthInit = () => {
  return {
    type: Const.AUTH_INIT,
  };
};

interface IParams {
  sid: string;
  status_session: StatusSession;
  email?: string;
  first_name?: string;
  max_age?: number;
  avatar_url?: string;
}

export const setAuthParams = (payload: IParams) => {
  return {
    type: Const.AUTH_PARAMS_SUCCESS,
    payload,
  };
};

export const setAuthFail = () => {
  return {
    type: Const.AUTH_FAIL,
  };
};

export const setAuthLoading = () => {
  return {
    type: Const.AUTH_LOADING,
  };
};

export const setAuthLoadingEnd = () => {
  return {
    type: Const.AUTH_END_OF_LOADING,
  };
};

export const setSessionStatus = (payload: string) => {
  return {
    type: Const.AUTH_SESSION_STATUS,
    payload,
  };
};

export const setInitialApp = () => {
  return {
    type: Const.LOG_OUT,
  };
};

export const setNewNameFromWS = (payload: string) => {
  return {
    type: Const.AUTH_SET_NEW_NAME_FROM_WS,
    payload,
  };
};

export const setHideHeader = (payload: boolean) => {
  return {
    type: Const.AUTH_HIDE_HEADER,
    payload,
  };
};

export const setEmail = (payload: string) => {
  return {
    type: Const.AUTH_SET_EMAIL,
    payload,
  };
};

export const setAuthAvatarUrl = (payload: string | undefined) => {
  return {
    type: Const.AUTH_AVATAR_URL,
    payload,
  };
};

export const applyCookie = (payload: boolean) => {
  return {
    type: Const.AUTH_APPLY_COOKIE,
    payload,
  };
};
