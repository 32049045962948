import coreRu from "./public/locales/ru/core.json";
import coreUa from "./public/locales/ua/core.json";
import coreEn from "./public/locales/en/core.json";
import servicesEn from "./public/locales/en/services.json";
import servicesRu from "./public/locales/ru/services.json";
import servicesUa from "./public/locales/ua/services.json";
import landingEn from "./public/locales/en/landing.json";
import landingRu from "./public/locales/ru/landing.json";
import landingUa from "./public/locales/ua/landing.json";
import automationEn from "./public/locales/en/automation.json";
import automationRu from "./public/locales/ru/automation.json";
import automationUa from "./public/locales/ua/automation.json";
import settingsEn from "./public/locales/en/settings.json";
import settingsRu from "./public/locales/ru/settings.json";
import settingsUa from "./public/locales/ua/settings.json";
import changeEmailConfirmEn from "./public/locales/en/changeEmailConfirm.json";
import changeEmailConfirmRu from "./public/locales/ru/changeEmailConfirm.json";
import changeEmailConfirmUa from "./public/locales/ua/changeEmailConfirm.json";
import checkInvitationEn from "./public/locales/en/checkInvitation.json";
import checkInvitationRu from "./public/locales/ru/checkInvitation.json";
import checkInvitationUa from "./public/locales/ua/checkInvitation.json";
import dashboardEn from "./public/locales/en/dashboard.json";
import dashboardRu from "./public/locales/ru/dashboard.json";
import dashboardUa from "./public/locales/ua/dashboard.json";
import setEmailEn from "./public/locales/en/setEmail.json";
import setEmailRu from "./public/locales/ru/setEmail.json";
import setEmailUa from "./public/locales/ua/setEmail.json";
import setNameEn from "./public/locales/en/setName.json";
import setNameRu from "./public/locales/ru/setName.json";
import setNameUa from "./public/locales/ua/setName.json";
import subscriptionEn from "./public/locales/en/subscription.json";
import subscriptionRu from "./public/locales/ru/subscription.json";
import subscriptionUa from "./public/locales/ua/subscription.json";
import scenarioEn from "./public/locales/en/scenario.json";
import scenarioRu from "./public/locales/ru/scenario.json";
import scenarioUa from "./public/locales/ua/scenario.json";
import supportEn from "./public/locales/en/support.json";
import supportRu from "./public/locales/ru/support.json";
import supportUa from "./public/locales/ua/support.json";
import documentationEn from "./public/locales/en/documentation.json";
import documentationRu from "./public/locales/ru/documentation.json";
import documentationUa from "./public/locales/ua/documentation.json";

export const en = {
  core: coreEn,
  services: servicesEn,
  landing: landingEn,
  automation: automationEn,
  settings: settingsEn,
  changeEmailConfirm: changeEmailConfirmEn,
  checkInvitation: checkInvitationEn,
  dashboard: dashboardEn,
  setEmail: setEmailEn,
  setName: setNameEn,
  subscription: subscriptionEn,
  scenario: scenarioEn,
  support: supportEn,
  documentation: documentationEn,
};

export const ru = {
  core: coreRu,
  services: servicesRu,
  landing: landingRu,
  automation: automationRu,
  settings: settingsRu,
  changeEmailConfirm: changeEmailConfirmRu,
  checkInvitation: checkInvitationRu,
  dashboard: dashboardRu,
  setEmail: setEmailRu,
  setName: setNameRu,
  subscription: subscriptionRu,
  scenario: scenarioRu,
  support: supportRu,
  documentation: documentationRu,
};
export const ua = {
  core: coreUa,
  services: servicesUa,
  landing: landingUa,
  automation: automationUa,
  settings: settingsUa,
  changeEmailConfirm: changeEmailConfirmUa,
  checkInvitation: checkInvitationUa,
  dashboard: dashboardUa,
  setEmail: setEmailUa,
  setName: setNameUa,
  subscription: subscriptionUa,
  scenario: scenarioUa,
  support: supportUa,
  documentation: documentationUa,
};

export const ns = [
  "core",
  "services",
  "landing",
  "automation",
  "settings",
  "changeEmailConfirm",
  "checkInvitation",
  "dashboard",
  "setEmail",
  "setName",
  "subscription",
  "scenario",
  "support",
  "documentation",
];
