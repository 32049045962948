import React from "react";
import styles from "./style.module.scss";

interface IProps {
  className?: string;
}

export const AuthLoader = ({ className }: IProps) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.loader} />
    </div>
  );
};
