import React from "react";
import Swal, { SweetAlertIcon } from "sweetalert2";
import i18n from "i18n";
import "./dark.min.css";

import { AuthLoader } from "components/authLoader";
import { sendEvent } from "creators/googleAnalytics";

import pic1 from "assets/landing/digital1.webp";
import pic2 from "assets/landing/digital2.webp";
import pic3 from "assets/landing/digital3.webp";
import pic4 from "assets/landing/digital4.webp";
import pic5 from "assets/landing/digital5.webp";
import pic6 from "assets/landing/digital6.webp";
import pic7 from "assets/landing/digital7.webp";
import pic8 from "assets/landing/digital8.webp";
import pic9 from "assets/landing/digital9.webp";

import pic1ua from "assets/png/pic1ua.png";
import pic2ua from "assets/png/pic2ua.png";
import pic3ua from "assets/png/pic3ua.png";
import pic5ua from "assets/png/pic5ua.png";
import pic6ua from "assets/png/pic6ua.png";
import pic7ua from "assets/png/pic7ua.png";
import pic8ua from "assets/png/pic8ua.png";
import pic9ua from "assets/png/pic9ua.png";

import pic1en from "assets/png/pic1en.png";
import pic2en from "assets/png/pic2en.png";
import pic3en from "assets/png/pic3en.png";
import pic5en from "assets/png/pic5en.png";
import pic6en from "assets/png/pic6en.png";
import pic7en from "assets/png/pic7en.png";
import pic8en from "assets/png/pic8en.png";
import pic9en from "assets/png/pic9en.png";

import advantage1XS from "assets/landing/advantage1_xs.webp";
import advantage2XS from "assets/landing/advantage2_xs.webp";
import advantage3XS from "assets/landing/advantage3_xs.webp";
import advantage1 from "assets/landing/advantage1.webp";
import advantage2 from "assets/landing/advantage2.webp";
import advantage3 from "assets/landing/advantage3.webp";

import adv1ua from "assets/png/adv1ua.png";
import adv2ua from "assets/png/adv2ua.png";
import adv3ua from "assets/png/adv3ua.png";

import adv1en from "assets/png/adv1en.png";
import adv2en from "assets/png/adv2en.png";
import adv3en from "assets/png/adv3en.png";

import adv1uaSX from "assets/png/adv1ua.jpg";
import adv2uaSX from "assets/png/adv2ua.jpg";
import adv3uaSX from "assets/png/adv3ua.jpg";

import adv1enSX from "assets/png/adv1en.jpg";
import adv2enSX from "assets/png/adv2en.jpg";
import adv3enSX from "assets/png/adv3en.jpg";

import example1ua from "assets/png/example1ua.png";
import example2ua from "assets/png/example2ua.png";
import example3ua from "assets/png/example3ua.png";

import example1en from "assets/png/example1en.png";
import example2en from "assets/png/example2en.png";
import example3en from "assets/png/example3en.png";

import automationUA from "assets/png/automationUA.png";
import automationEN from "assets/png/automationEN.png";

import dashboardUA from "assets/png/dashboardUA.png";
import dashboardEN from "assets/png/dashboardEN.png";

export const SessionResponseError = {
  400: "Session does not exist!",
  403: "Forbidden",
  404: "Not found",
  500: "Internal server error",
};

export const errorResponseNotification = (
  status: 400 | 403 | 404 | 500,
  timer: number = 5000,
  message?: string
) => {
  const formattedMessage = capitalizeFirstLetter(
    replaceSymbolFromStr(" ", "_", message)
  );
  Swal.fire({
    title: "Something went wrong",
    text: message ? formattedMessage : SessionResponseError[status],
    icon: "error",
    toast: true,
    position: "bottom-right",
    timer,
    background: "#1D1D1D",
    customClass: {
      popup: "black-popup",
      title: "title-popup",
      content: "content-popup",
    },
  });
};

export const successNotification = (
  text: string,
  title: string = "",
  timer: number = 5000
) => {
  Swal.fire({
    title,
    text,
    icon: "success",
    toast: true,
    position: "bottom-right",
    timer,
    customClass: {
      popup: "black-popup",
      title: "title-popup",
      content: "content-popup",
    },
  });
};

export const errorNotification = (
  text: string,
  title: string = "",
  timer: number = 60000
) => {
  Swal.fire({
    title,
    text,
    icon: "error",
    timer,
    toast: true,
    position: "bottom-right",
    customClass: {
      popup: "black-popup",
      title: "title-popup",
      content: "content-popup",
    },
  });
};

interface IShowAlertMessage {
  title: string;
  html: string;
  timer?: number;
  timerProgressBar?: boolean;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showConfirmButton?: boolean;
  icon?: SweetAlertIcon;
}
export const showAlertWithMessage = ({
  title,
  html,
  timer = 1000,
  timerProgressBar = true,
  showCancelButton,
  showCloseButton,
  confirmButtonText,
  cancelButtonText,
  showConfirmButton,
  icon,
}: IShowAlertMessage) => {
  Swal.fire({
    title,
    html,
    timer,
    timerProgressBar,
    showCancelButton,
    showCloseButton,
    confirmButtonText,
    cancelButtonText,
    showConfirmButton,
    icon,
  });
};

export function withSuspense(WrappedComponent: React.ComponentType<any>) {
  return (props: any) => {
    return (
      <React.Suspense fallback={<AuthLoader />}>
        <WrappedComponent {...props} />
      </React.Suspense>
    );
  };
}

export function getCookie(name: string): string | null {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
    " " +
    sizes[i]
  ).toString();
}

export const errorFromResponse = (error: string) => {
  if (error) {
    errorNotification(i18n.t(error));
    return i18n.t(error);
  } else {
    errorNotification(i18n.t("errorOccurred"));
    return i18n.t("errorOccurred");
  }
};

export const WS_CONNECTING = 0;
export const WS_OPEN = 1;
export const WS_CLOSING = 2;
export const WS_CLOSED = 3;

export const changeTranslation = (lang: string) => {
  i18n.changeLanguage(lang);
  sendEvent("User", "Change", "Change language to " + lang, 1);
};

export const languageList = () => {
  return [
    { value: "uk" || "uk-UA", label: "UA" },
    { value: "en", label: "EN" },
  ];
};

export const languageLabel = (lang: "uk" | "en" | "uk-UA") => {
  if (lang === "uk" || lang === "uk-UA") {
    return "UA";
  } else {
    return lang.toUpperCase();
  }
};

export function escapeRegExp(string: string) {
  // eslint-disable-next-line no-useless-escape
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export const getFirstLetter = (s?: string) => {
  return s?.charAt(0);
};

export function capitalizeFirstLetter(string?: string) {
  return string ? getFirstLetter(string)?.toUpperCase() + string.slice(1) : "";
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const languageDetector = (
  lang: "uk" | "uk-UA" | "en"
): "uk" | "uk-UA" | "en" => {
  const langs = ["uk", "en", "uk-UA"];
  if (langs.includes(lang)) {
    return lang;
  } else {
    return "en";
  }
};

export const replaceSymbolFromStr = (
  newPart: string,
  oldPart: string,
  value?: string
) => {
  return value?.replaceAll(oldPart, newPart);
};

export type AssetsNames =
  | "pic1"
  | "pic2"
  | "pic3"
  | "pic4"
  | "pic5"
  | "pic6"
  | "pic7"
  | "pic8"
  | "pic9";

export const landingAssetsByLocale = (
  locale: "uk" | "uk-UA" | "en",
  name: AssetsNames
) => {
  const ruAssets = {
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
  };

  const enAssets = {
    pic1: pic1en,
    pic2: pic2en,
    pic3: pic3en,
    pic4,
    pic5: pic5en,
    pic6: pic6en,
    pic7: pic7en,
    pic8: pic8en,
    pic9: pic9en,
  };

  const uaAssets = {
    pic1: pic1ua,
    pic2: pic2ua,
    pic3: pic3ua,
    pic4,
    pic5: pic5ua,
    pic6: pic6ua,
    pic7: pic7ua,
    pic8: pic8ua,
    pic9: pic9ua,
  };

  const result = {
    ru: ruAssets,
    "uk-UA": uaAssets,
    uk: uaAssets,
    ua: uaAssets,
    en: enAssets,
  };

  return result[languageDetector(locale)][name];
};

export const setAdvantageImgByLocale = (
  locale: "uk" | "uk-UA" | "en",
  name: "adv1" | "adv2" | "adv3",
  sx: boolean
) => {
  const ruResult = {
    adv1: advantage1,
    adv2: advantage2,
    adv3: advantage3,
  };

  const uaResult = {
    adv1: adv1ua,
    adv2: adv2ua,
    adv3: adv3ua,
  };

  const enResult = {
    adv1: adv1en,
    adv2: adv2en,
    adv3: adv3en,
  };

  const result = {
    ru: ruResult,
    "uk-UA": uaResult,
    ua: uaResult,
    uk: uaResult,
    en: enResult,
  };

  const uaResultSX = {
    adv1: adv1uaSX,
    adv2: adv2uaSX,
    adv3: adv3uaSX,
  };

  const enResultSX = {
    adv1: adv1enSX,
    adv2: adv2enSX,
    adv3: adv3enSX,
  };

  const ruResultSX = {
    adv1: advantage1XS,
    adv2: advantage2XS,
    adv3: advantage3XS,
  };

  const resultSX = {
    ru: ruResultSX,
    ua: uaResultSX,
    uk: uaResultSX,
    "uk-UA": uaResultSX,
    en: enResultSX,
  };

  return sx
    ? resultSX[languageDetector(locale)][name]
    : result[languageDetector(locale)][name];
};

export const handleExampleImgByLocale = (
  locale: "uk" | "uk-UA" | "en",
  name: "ex1" | "ex2" | "ex3"
) => {
  const uaResult = {
    ex1: example1ua,
    ex2: example2ua,
    ex3: example3ua,
  };

  const enResult = {
    ex1: example1en,
    ex2: example2en,
    ex3: example3en,
  };

  const result = {
    ua: uaResult,
    uk: uaResult,
    "uk-UA": uaResult,
    en: enResult,
  };

  return result[languageDetector(locale)][name];
};

export const handleAutomationBgByLocale = (locale: "uk" | "uk-UA" | "en") => {
  const result = {
    uk: automationUA,
    ua: automationUA,
    "uk-UA": automationUA,
    en: automationEN,
  };

  return result[languageDetector(locale)];
};

export const handleDashboardBgByLocale = (locale: "uk" | "uk-UA" | "en") => {
  const result = {
    uk: dashboardUA,
    ua: dashboardUA,
    "uk-UA": dashboardUA,
    en: dashboardEN,
  };

  return result[languageDetector(locale)];
};

export const domainDetector = () => {
  const domain = window.location.hostname;
  if (domain.includes("devel")) {
    return ".devel";
  } else if (domain.includes("testing")) {
    return ".testing";
  } else if (domain.includes("beta")) {
    return ".beta";
  } else {
    return "";
  }
};

export const envLinkSetter = () => {
  if (window.location.origin.includes("localhost")) {
    return "";
  } else if (window.location.origin.includes("devel")) {
    return "https://app.devel.digitabpm.io";
  } else if (window.location.origin.includes("testing")) {
    return "https://app.testing.digitabpm.io";
  } else if (window.location.origin.includes("beta")) {
    return "https://app.beta.digitabpm.io";
  } else {
    return "https://app.digitabpm.io";
  }
};
