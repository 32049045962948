import { Action } from "redux";
import { Api } from "../enums/api";

import { post } from "creators";

export const setSessionLanguage = (language: string) => {
  return (_dispatch: (action: Action) => void) => {
    post(Api.SessionSetLanguage, { language }).then((res) => {
      console.log(res);
    });
  };
};

export const setAccountLanguage = (language: string) => {
  return (_dispatch: (action: Action) => void) => {
    post(Api.AccountSetLanguage, { language }).then((res) => {
      console.log(res);
    });
  };
};
