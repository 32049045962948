import { RootState } from "reducers";
import { createSelector } from "reselect";

export const isLoading$ = (state: RootState) => state.auth.isLoading;
export const isError$ = (state: RootState) => state.auth.isError;
export const email$ = (state: RootState) => state.auth.email;
export const sid$ = (state: RootState) => state.auth.sid;
export const first_name$ = (state: RootState) => state.auth.first_name;
export const status_session$ = (state: RootState) => state.auth.status_session;
export const max_age$ = (state: RootState) => state.auth.max_age;
export const plan_name$ = (state: RootState) => state.auth.plan_name;
export const plan_id$ = (state: RootState) => state.auth.plan_id;
export const subscription_cost$ = (state: RootState) =>
  state.auth.subscription_cost;
export const subscription_periodicity$ = (state: RootState) =>
  state.auth.subscription_periodicity;

export const sidMemoized$ = createSelector(sid$, (sid) => {
  return sid;
});

export const isNeedToHideHeader$ = (state: RootState) =>
  state.auth.isNeedToHideHeader;

export const avatar$ = (state: RootState) => state.auth.avatar_url;
export const company_role$ = (state: RootState) => state.auth.company_role;
export const cookieApplied$ = (state: RootState) =>
  state.auth.consent_on_cookie;
