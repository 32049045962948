import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";

import { isLoading$ } from "selectors/auth";
import { AuthLoader } from "components/authLoader";
import "./App.scss";
import { withSuspense } from "utils";
import * as LangActions from "actions/lang";
import * as AuthCreators from "creators/auth";
import LangState from "interfaces/state/lang";
import { RootState } from "reducers";

const PublicContainer = React.lazy(() =>
  import("modules/public").then(({ PublicModule }) => ({
    default: PublicModule,
  }))
);

const SuspendedPublic = withSuspense(PublicContainer);

interface OwnProps {}

interface IStateProps {
  isLoading: boolean;
}

interface IDispatchProps {
  getSessionStatus: () => void;
  setLanguage: (lang: LangState["lang"]) => void;
}
interface IProps
  extends IStateProps,
    IDispatchProps,
    OwnProps,
    RouteComponentProps,
    WithTranslation {}
class App extends React.PureComponent<IProps> {
  componentDidMount() {
    const { setLanguage, i18n, getSessionStatus } = this.props;
    setLanguage(i18n.language as LangState["lang"]);

    getSessionStatus();
  }

  private renderPublicRoutes = () => {
    return <SuspendedPublic />;
  };

  public render() {
    const { isLoading } = this.props;
    return (
      <div className="App">
        {isLoading && <AuthLoader />}
        {this.renderPublicRoutes()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: isLoading$(state),
  };
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  props: OwnProps
) => {
  return {
    ...stateProps,
    getSessionStatus: dispatchProps.getSessionStatus,
    setLanguage: dispatchProps.setLanguage,
    ...props,
  };
};

export default withRouter(
  withTranslation()(
    connect(
      mapStateToProps,
      {
        getSessionStatus: AuthCreators.getSessionStatus,
        setLanguage: LangActions.setLanguage,
      },
      mergeProps
    )(App)
  )
);
