import * as Const from "../constants";
import State, { StatusSession } from "../interfaces/state/auth";
import { setCookieApply } from "interceptors";
import * as Interceptors from "interceptors/index";

const initState: State = {
  isLoading: false,
  isError: false,
  isAuth: false,
  sid: "",
  status_session: StatusSession.INITIAL,
  email: undefined,
  first_name: undefined,
  max_age: undefined,
  plan_name: undefined,
  subscription_cost: undefined,
  subscription_periodicity: undefined,
  plan_id: undefined,
  isNeedToHideHeader: false,
  avatar_url: undefined,
  company_role: undefined,
  consent_on_cookie: Interceptors.getCookieApply(),
};

export default function auth(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.AUTH_INIT:
    case Const.LOG_OUT:
      return {
        ...initState,
        status_session: StatusSession.UNKNOWN,
      };

    case Const.AUTH_PARAMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        sid: action.payload.sid,
        status_session: action.payload.status,
        email: action.payload.email,
        first_name: action.payload.first_name,
        max_age: action.payload.max_age,
        plan_name: action.payload.plan_name,
        subscription_cost: action.payload.subscription_cost,
        subscription_periodicity: action.payload.subscription_periodicity,
        plan_id: action.payload.plan_id,
        avatar_url: action.payload.avatar_url,
        company_role: action.payload.company_role,
        consent_on_cookie: action.payload.consent_on_cookie,
      };

    case Const.AUTH_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isAuth: false,
      };

    case Const.AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.AUTH_END_OF_LOADING:
      return {
        ...state,
        isLoading: false,
        isAuth: true,
      };

    case Const.AUTH_SESSION_STATUS:
      return {
        ...state,
        status_session: action.payload,
      };

    case Const.AUTH_SET_NEW_NAME_FROM_WS:
      return {
        ...state,
        first_name: action.payload,
      };

    case Const.AUTH_HIDE_HEADER:
      return {
        ...state,
        isNeedToHideHeader: action.payload,
      };

    case Const.AUTH_SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case Const.AUTH_AVATAR_URL: {
      return {
        ...state,
        avatar_url: action.payload,
      };
    }

    case Const.AUTH_APPLY_COOKIE: {
      setCookieApply(action.payload);
      return {
        ...state,
        consent_on_cookie: action.payload,
      };
    }

    default:
      return state;
  }
}
