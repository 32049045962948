import axios from "axios";

export const post = (url: string, data?: object) => {
  axios.defaults.withCredentials = true;
  return axios({
    url,
    method: "POST",
    data,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => error.response);
};
