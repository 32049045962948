import { combineReducers } from "redux";

import auth from "./auth";
import lang from "./lang";

const rootReducer = combineReducers({
  auth,
  lang,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
