import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router";
import ReactGA from "react-ga";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { successNotification } from "./utils";
import "./index.css";
import "./App.scss";
import historyItem from "./historyItem";
import "./i18n";
import * as H from "history";
import * as pack from "../package.json";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={historyItem as H.History}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

ReactGA.initialize("UA-214961866-1");

historyItem.listen((location) => {
  ReactGA?.pageview(
    location.pathname + location.search,
    [],
    window.location.href
  );
  ReactGA?.set({ page: window.location.host + "/" + location.pathname });
});

console.log("Landing version -", pack.version);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => {
    // caches.keys().then(async function (names) {
    //   await Promise.all(names.map(name => caches.delete(name)));
    // });
  },
  onUpdate: async () => {
    successNotification(
      "New version available. the application will restart to update!",
      "Success"
    );

    caches
      .keys()
      .then(async function (names) {
        await Promise.all(names.map((name) => caches.delete(name)));
      })
      .then(() => setTimeout(() => window.location.reload(), 1000));
  },
});
